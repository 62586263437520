.increase-balance-event {
    display: flex;
    align-items: start;
}

.increase-balance-event__card-image {
    margin-right: 50px;
}

.card-empty-image {
    width: 187px;
    height: 117px;
    background-color: #53B741;
    border-radius: 10px;
    opacity: 0.5;
    display: flex;
    align-items: center;
    vertical-align: center;
}

.card-empty-image__point-circle {
    border-radius: 50%;
    width: 75px;
    height: 75px;
    background: #fff;
    color: #53B741;
    text-align: center;
    line-height: 75px;
    margin: auto;
    font-size: 36px;
    font-weight: bold;
}


.increase-balance-event__name {
    width: 700px;
    padding-right: 100px;
}

.increase-balance-event__body {
    width: 700px;
    padding-right: 100px;
}

.increase-balance-event__description {
}


.increase-balance-event__title {
    font-size: 24px;
    color: #333333;
    font-weight: bold;
    margin-bottom: 20px;
}

.increase-balance-event__total {
    font-size: 36px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 5px;
}

.increase-balance-event__date {
    font-size: 16px;
    font-weight: 600;
    color: #868686;
    margin-bottom: 5px;
}

@media (max-width: 786px) {
    .increase-balance-event__title{
        font-size: 16px;
    }
    .card-empty-image {
        width: 65px;
        height: 40px;
        border-radius: 5px;
    }

    .card-empty-image__point-circle {
        border-radius: 50%;
        width: 20px;
        height: 20px;
        line-height: 20px;
        margin: auto;
        font-size: 10px;
    }

    .increase-balance-event__card-image {
        margin-right: 20px;
        min-width: 65px;
    }

    .increase-balance-event__body {
        width: auto;
        padding-right: 20px;
        flex-grow: 1;
    }

    .increase-balance-event__total {
        font-size: 18px;
    }
    .increase-balance-event__date {
        font-size: 9px;
    }

    .increase-balance-event__description {
        min-width: 89px;
        text-align: right;
    }
}