.link{
    transition: all 100ms linear;
}


.link--button{
    color: #222222;
    text-decoration: none;
    display: inline-block;
    border-radius: 5px;
    padding: 10px 20px;
}

.link--button.link--primary{
    background-color: #3884BE;
    color: white;
}

.link--button.link--primary:hover{
    background-color: #1E9DF4;
}

.link--button.link--rounded{
    border-radius: 20px;
}