.nav-bar {
  display: flex;
  align-items: center;
}

.nav-bar__item {
  margin-left: 25px;
}

.nav-bar__item:first-child {
  margin-left: 0;
}

.nav-bar__link {
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  position: relative;
  display: block;
  color: #222222;
  white-space: nowrap;
}

.nav-bar__link.active::after {
  content: "";
  position: absolute;
  height: 3px;
  width: 100%;
  background-color: #0074c2;
  bottom: -10px;
  display: block;
}

.nav-bar-dropbox-link {
  position: relative;
}

.nav-bar-dropbox-link__dropbox {
  position: absolute;
  border: 1px solid #ececec;
  background-color: white;
  border-radius: 5px;
  z-index: 100;
  box-shadow: 0 3px 6px #00000029;
}

.nav-bar-dropbox-link__header {
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  position: relative;
  display: block;
  color: #222222;
}

.nav-bar-dropbox-link__link {
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  position: relative;
  display: block;
  color: #222222;
  white-space: nowrap;
  padding: 10px 20px;
}
