.nlmk-offer-modal-window {
    /*max-height: 800px;*/

}

.nlmk-offer-modal-window__body {
    max-width: 900px;
    overflow-y: scroll;
    max-height: 350px;
}

.nlmk-offer-modal-window__footer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 25px;
}