.grid{
    display: grid;
    align-items: stretch;

}

.grid--gap-30{
    grid-gap: 30px;
}

.grid--columns-2{
    grid-template-columns: 1fr 1fr;
}

.grid--columns-3{
    grid-template-columns: 1fr 1fr 1fr 1fr;
}