.wave-page {
    /*padding-top: 140px;*/
}

.wave-page__waves{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
    align-items: stretch;
    margin-bottom: 40px;
}

.wave-page__title{
    margin-bottom: 25px;
}

.wave-page__text{
    font-size: 20px;
    color:#333333 ;
    font-weight: 500;
    margin-bottom: 40px;
}
