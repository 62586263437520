.accordion-title {
    display: flex;
    padding: 1rem 1rem;
    cursor: pointer;
    border: 1px solid #e0e0e0;
    border-radius: 0.25rem;
    background-color: #fff;
    box-shadow: 2px 2px 2px #e0e0e0;
    transition: background-color 0.2s ease-in-out;
    justify-content: space-between;
    align-items: center;
}

.accordion-title--active {
    border-radius: 0.25rem 0.25rem 0 0;
}

.accordion-title__icon {
    content: '';
    display: block;
    width: 0.75rem;
    height: 0.75rem;
    border-top: 2px solid #868686;
    border-right: 2px solid #868686;
    transform: rotate(135deg);
    transition: transform 0.2s ease-in-out;
}

.accordion-title__icon--active {
    transform: rotate(-45deg);
}

.accordion-content {
    border: 1px solid #e0e0e0;
    border-top: none;
    border-radius: 0 0 0.25rem 0.25rem;
    background-color: #fff;
    box-shadow: 2px 2px 2px #e0e0e0;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.2s ease-in-out;
    line-height: 1.5rem;
}

.accordion-content--active {
    visibility: visible;
    opacity: 1;
    padding: 1rem 1rem;

}





