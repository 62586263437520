.table {
    width: 100%;
    border-spacing: 0;
}

.table__header {
    border: 5px solid blue;
}

.table__row {
    border: 5px solid blue;
}

.table__header {
    border: 5px solid green;
}

.table__column {
    color: #333333;
    padding: 10px 20px 10px 0;
    font-size: 16px;
    font-weight: 500;
}

.table__column:first-child{
    padding-left: 10px;
}

.table__column--header {
    font-weight: 500;
    font-size: 14px;
    color: #999999;
}

.table__row--zebra:nth-child(2n+1) {
    background: #F5F5F5;
}