.alert{
    padding: 30px 40px;
    border-radius: 5px;
    
}

.alert--warning{
    background-color: #FFDE6F;
    color: #333333;
}

.alert--success{
    background-color: #53b741;
    color: #333333;
}

.alert .alert-column{

}