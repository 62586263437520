.sale-products-table {
    display: flex;
    flex-direction: column;
}

.sale-products-table__header {

}


.sale-products-table__header-row {
    display: flex;
    flex-direction: row;
    font-weight: 500;
    font-size: 14px;
    color: #999999;
}

.sale-products-table__header-cell {
    padding: 10px 20px 10px 0;
    font-size: 16px;
    font-weight: 500;
}

.sale-products-table__body {
    display: flex;
    flex-direction: column;
}

.sale-products-table__body-row {
    display: flex;
    flex-direction: row;
    font-weight: 500;
    font-size: 14px;
    color: #999999;
}

.sale-products-table__body-cell {
    color: #333333;
    padding: 10px 20px 10px 0;
    font-size: 16px;
    font-weight: 500;
}