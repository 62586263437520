.order-details-page {
    /*padding-top: 140px;*/
}

.order-details-page__header {
    display: flex;
    align-items: center;
}

.order-details-page__body {
    margin-top: 30px;
}

.order-details-page__order-items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    align-items: stretch;
    grid-gap: 20px;
    margin-bottom: 10px;
}
.order-details-page__order-items .order-item {
    /*margin-right: 25px;*/
}

.order-details-page__header > .order-details-page__header-left {

}

.order-details-page__header > .order-details-page__header-middle {
    flex-grow: 1;
}

.order-details-page__header > .order-details-page__header-right {
    text-align: right;
}

.order-details-page__header-title {
    display: flex;
    align-items: center;
    margin-left: -28px;
    margin-bottom: 10px;
}

.order-details-page__header-title-arrow {
    margin-right: 5px;
    border-color: #0074C2;
    cursor: pointer;
}

.order-details__date {
    font-size: 14px;
    font-weight: 600;
    color: #868686;
    margin-right: 5px;
}

.order-details__wrapper {
    display: flex;
    align-items: flex-end;
    margin-top: 10px;
}

@media (max-width: 786px) {
    .order-details__date {
        font-size: 10px;
    }

    .order-details-page__body {
        margin-top: 15px;
    }

    .order-details-page__order-items {
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
    }

}