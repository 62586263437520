.modal-window {
  background: #ffffffd4 0 0 no-repeat padding-box;
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.modal-window__content {
  box-shadow: 0 3px 6px #00000029;
  border-radius: 5px;
  background-color: white;
  padding: 50px;
  margin: auto;
  cursor: auto;
  position: relative;
}

.modal-window-enter {
  opacity: 0;
  top: 100px;
}

.modal-window-enter-active {
  opacity: 1;
  top: 0;
  transition: opacity 500ms, top 500ms;
}

.modal-window-exit {
  opacity: 1;
  top: 0;
}

.modal-window-exit-active {
  opacity: 0;
  transition: opacity 500ms, top 500ms;
  top: 100px;
}
