.order-item {
}

.order-item__image img{
    border-radius: 10px;
    display: block;
    width: 100%;
}

.order-item__name {
    text-align: center;
    margin-top: 10px;
    font-weight: 600;

}

@media (max-width: 786px) {
    .order-item__name {
        margin-top: 5px;
    }
}