.product-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 30px;
    align-items: stretch;
}

.product-list__item {
    opacity: 1;
    transition: all 500ms;
}

.product-list__item-enter {
    opacity: 0;
}

.product-list__item-enter-active {
    opacity: 1;
    transition: opacity 500ms;
}

.product-list__item-exit {
    opacity: 1;
}

.product-list__item-exit-active {
    opacity: 0;
    transition: opacity 500ms;
}

@media (max-width: 1100px) {
    .product-list {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 786px) {
    .product-list {
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
    }

    .products-page__filter {
        margin-bottom: 20px;
    }

    .filter__item {
        padding: 9px 11px;
        font-size: 12px;
        margin-right: 5px;
        margin-bottom: 5px;
        cursor: pointer;
    }

    .products-page__slider {
        margin-bottom: 20px;
    }
}
