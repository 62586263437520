.sales-table {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-spacing: 0;
}

.sales-table__header {
    display: flex;
    flex-direction: column;
}

.sales-table__header-row {
    display: flex;
    flex-direction: row;
    font-weight: 500;
    font-size: 14px;
    color: #999999;
}

.sales-table__header-cell {
    padding: 10px 20px 10px 0;
    font-size: 16px;
    font-weight: 500;
}


.sales-table__body {
    display: flex;
    flex-direction: column;
}

.sales-table__body-row {
    display: flex;
    flex-direction: row;
    font-weight: 500;
    font-size: 14px;
    color: #999999;
    cursor: pointer;
}

.sales-table__body-cell {
    color: #333333;
    padding: 10px 20px 10px 0;
    font-size: 16px;
    font-weight: 500;
}
.sales-table__body-cell > .arrow {
    border-color: #3884be;
    margin-right: 5px;
    margin-bottom: 3px;
}

.sales-table__body-cell--blue {
    color: #3884be;
}

.sales-table__sale-products {
    overflow: hidden;  /* Hide the element content, while height = 0 */
    height: 0;
    opacity: 0;
    transition: height 0ms 400ms, opacity 400ms 0ms;
    margin-left: 50px;

}

.sales-table__sale-products--expanded {
    height: auto; opacity: 1;
    transition: height 0ms 0ms, opacity 600ms 0ms;
}


.sales-table__body-cell:first-child, .sales-table__header-cell:first-child {
    padding-left: 10px;
}

.sales-table__body-row--zebra:nth-child(4n+1) {
    background: #F5F5F5;
}