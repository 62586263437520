.activate-code {
}

.activate-code-message {
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 20px 0;
}
.activate-code-form {
    max-width: 500px;
}

.activate-code-message--error {
    color: #C2044B;
}

.activate-code-message--success {
    color: #63c44e;
}

@media (max-width: 1100px) {
    .activate-code {
        width: 100%;
    }
}
