.events-page{
    /*padding-top: 140px;*/
}

.events-page__title{
    margin-bottom: 20px;
}

.events-page__filter{
    margin-bottom: 70px;
}

.events-page__item{
    margin-bottom: 70px;
}

@media (max-width: 786px) {
    .events-page__filter {
        margin-bottom: 30px;
    }
}