.wave-details-page{
    /*padding-top: 120px;*/
    margin-bottom: 100px;
}
.wave-details-page__banner{
    margin-bottom: 50px;
}


.wave-details-page__body{
    display: flex;
    padding-top: 60px;
}

.wave-details-page__grid{
    padding-top: 60px;
}

.wave-details-page__sales{
    width: 50%;
    padding-right: 100px;
}

.wave-details-page__description{
    width: 50%;
}

.wave-details-page__title{

}

.wave-details-page__tabs{
    display: flex;
    font-size: 18px;
}

.wave-details-page__tab{
    margin-right: 40px;
    padding-bottom: 10px;
    cursor: pointer;
    font-size: 24px;
    font-weight: 300;
}

.wave-details-page__tab--active{
    font-weight: 600;
    border-bottom: 3px solid #0074c2;
}

.wave-details-page__table{
    /*width: 600px;*/
}

.wave-details-page__body{

}

.wave-details-page__body img{
    width: 100%;
}

@media (max-width: 320px) {
    .wave-details-page__tab{
        margin-right: 20px;
        font-size: 23px;
    }


}

@media (max-width: 786px) {
    .wave-details-page__tab{
        margin-right: 20px;
        font-size: 16px;
    }

    .wave-details-page__grid {
        padding-top: 20px;
    }

    .products-in-wave__group-name {
        font-size: 16px;
    }



}
