.cart-item {
    display: flex;

    width: 730px;
    align-items: start;
}

.cart-item__image {
    width: 297px;
    min-width: 297px;
    max-width: 297px;
    margin-right: 30px;
    border-radius: 10px;
}

.cart-item__image img {
    display: block;
    width: 100%;
    border-radius: 10px;
}

.cart-item__title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 28px;
}

.cart-item__body {
    display: flex;

    flex-direction: column;
    flex-grow: 1;
}

.cart-item__close:after {
    display: inline-block;
    content: "\00d7"; /* This will render the 'X' */
    font-size: xx-large;
    cursor: pointer;
    line-height: 16px;
}

.cart-item__line {
    display: flex;

    margin-bottom: 20px;
    align-items: start;
}

.cart-item__line:last-child {
    margin-bottom: 0;
}

.cart-item__name {
    width: 100px;
    font-size: 18px;
    font-weight: 500;
}

.cart-item__value {
    font-weight: bold;
    font-size: 18px;
}

@media (max-width: 786px) {
    .cart-item {
        width: 100%;
    }

    .cart-item__image {
        width: 120px;
        min-width: auto;
    }

    .cart-item__title {
        font-size: 16px;
        margin-bottom: 15px;
    }
    .cart-item__value {
        font-size: 14px;
    }

    .cart-item__line {
        margin-bottom: 7px;
    }

    .cart-item__name {
        font-size: 14px;
    }

}