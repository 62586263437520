.order-sender-to-email {
    display: flex;
    align-items: center;
}

.order-sender-to-email__img {
    width: 20px;
    margin-right: 5px;
}

.order-sender-to-email__text {
    color: #0074C2;
    cursor: pointer;
}