.cart-page {
  /*padding-top: 140px;*/
}

.cart-page__title{
  margin-bottom: 20px;
  font-size: 36px;
  font-weight: bold;
}

.cart-page__warning {
  display: none;
  font-size: 24px;
  margin-bottom: 20px;
}

.cart-page__body{
  display: flex;
}

.cart-page__products{
  flex-grow: 1;
}


.cart-page__stick-container{

}

.cart-page__stick-panel{
  position: sticky;
  top: 0;
}

@media (max-width: 1050px) {
  .cart-page__body{
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;
  }
}