.booklets__item {
    width: 50%;
    margin-bottom: 80px;
}

.booklet {
    display: flex;
    align-items: start;
}

.booklet__image {
    width: 218px;
    background-color: #333333;
}

.booklet__image img {
    display: block;
    width: 218px;
}

.booklet__body {
    padding: 30px;
}

.booklet__title {
    font-size: 24px;
    font-weight: 300;
    color: #333333;
    margin-bottom: 20px;
}

.booklet__text {
    font-size: 16px;
    font-weight: 300;
    color: #333333;
    margin-bottom: 30px;
}

@media (max-width: 480px) {
    .booklet__body {
        padding: 15px;
    }

    .booklet__title {
        font-size: 18px;
    }
    
    .booklet__text {
        font-size: 12px;
    }

    .booklet__image,
    .booklet__image img {
        width: 158px;
    }
}

@media (max-width: 320px) {
    .booklet {
        flex-wrap: wrap;
    }

    .booklet__body {
        padding: 0;
        padding-top: 15px;
    }
}