.point {
    display: inline-block;
}

.point__body {
    display: flex;
    align-items: center;
}

.point__icon {
    height: 18px;
    width: 18px;
    background-color: #ffde6f;
    display: block;
    color: #333333;
    font-size: 10px;
    font-weight: bold;
    line-height: 0;
    border-radius: 10px;
    position: relative;
}

.point__amount {
    margin-right: 5px;
}

.point__amount--big {
    font-size: 36px;
    font-weight: bold;
    color: #333333;
}

.point__amount--middle {
    font-size: 24px;
}

.point__amount--green {
    color: #53B741;
}

.point__icon:after {
    content: "B";
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    line-height: 18px;
    text-align: center;
}

.point__icon--big {
    height: 38px;
    width: 38px;
    border-radius: 20px;
}

.point__icon--big:after {
    font-size: 21px;
    line-height: 38px;
}

.point__icon--middle {
    height: 24px;
    width: 24px;
    border-radius: 24px;
}

.point__icon--middle:after {
    font-size: 14px;
    line-height: 24px;
}

@media (max-width: 786px) {
    .point__icon {
        border-radius: 12px;
        height: 12px;
        width: 12px;
        font-size: 8px;
    }

    .point__icon::after {
        line-height: 12px;
    }

    .point__icon--big {
        height: 24px;
        width: 24px;
        border-radius: 12px;
    }
    .point__icon--big::after {
        line-height: 24px;
        font-size: 12px;
    }

    .point__amount--big {
        font-size: 18px;
    }


    .point__icon--middle {
        height: 18px;
        width: 18px;
        border-radius: 18px;
    }

    .point__icon--middle:after {
        font-size: 10px;
        line-height: 18px;
    }

    .point__amount--middle {
        font-size: 18px;
    }
}