.products-in-wave{
  width: 100%;
}

.products-in-wave__head{
    border-radius: 5px;
    position: relative;
    width: 100%;
    background-color: #000000;
    margin-bottom: 25px;
}

.products-in-wave__title{
    font-size: 24px;
    font-weight: bold;
    color: white;
    position: absolute;
    top: 40px;
    left: 30px;
}


.products-in-wave__points{
    position: absolute;
    font-size: 18px;
    bottom: 40px;
    left: 30px;
    color: #333333;
    background-color: #ECECEC;
    padding: 5px 10px;
    font-weight: 600;
    border-radius: 5px;
}

.products-in-wave__head img{
    width: 100%;
    display: block;
    opacity: 0.5;
    border-radius: 5px;
}

.products-in-wave__head--r10, .products-in-wave__head--r10 img{
    border-radius: 10px;
}

.products-in-wave__image{
    width: 100%;
}

.products-in-wave__body{

}

.products-in-wave__group{
    margin-bottom: 25px;
}

.products-in-wave__group-name{
    color: #333333;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 15px;
}

.products-in-wave__group-name:hover{
    text-decoration: underline;
    cursor: pointer;
}

.products-in-wave__product-name{
    color: #333333;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 3px;
}

.products-in-wave__vendor-codes{
    display: flex;
    flex-wrap: wrap;
}

.products-in-wave__vendor-code{
    margin-right: 10px;
    color: #868686;
    font-size: 14px;
}

.products-in-wave__product{
    margin-bottom: 15px;
}

.products-in-wave__group-products{

}

@media (max-width: 360px) {
    .products-in-wave__title {
        font-size: 16px;
    }
}

@media (max-width: 375px) {
    .products-in-wave__title {
        font-size: 18px;
        top: 20px;
        left: 25px;
    }

    .products-in-wave__points {
        left: 25px;
    }
}

@media (max-width: 786px) {
    .products-in-wave__group-name  {
        font-size: 16px;
    }
}

