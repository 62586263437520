.confirmation-order-modal-window {
}

.confirmation-order-modal-window__title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 40px;
}

.confirmation-order-modal-window__body {
  display: flex;
}

.confirmation-order-modal-window__button {
  margin-right: 30px;
}
