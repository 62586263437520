.slide {
    width: 100%;
    /*height: 310px;*/
    background-color: #1e1e1e;
    position: relative;
    background-repeat: repeat-x;
    background-size: contain;
    border-radius: 10px;
}

.slide__spread {
    flex-grow: 1;
}

.slide__bottom {
    position: absolute;
    bottom: 35px;
    left: 40px;
}

.slide__date {
    color: white;
    margin-bottom: 10px;
}

.slide__title {
    color: white;
    z-index: 100;
    font-size: 28px;
    padding: 40px 0 0 40px;
    font-weight: bold;
    max-width: 800px;
    margin-bottom: 10px;
}

.slide__title-text--red {
    background-color: #C2044B;
    padding: 5px;
    border-radius: 5px;
}

.slide__text {
    padding: 40px;
    color: white;
    z-index: 100;
    font-size: 24px;
}

.slide__points {
    font-size: 22px;
    color: white;
    z-index: 100;
    padding: 10px 0 40px 40px;
}

.slide__top {
    left: 40px;
    right: 40px;
    top: 35px;
    position: absolute;
}

.slide__background {
    /*position: absolute;*/
    position: relative;
    width: 100%;
    background-color: #222222;
    opacity: 0.8;
    z-index: 50;
    display: block;
}

.slide__body {
    display: flex;
    height: 100%;
    z-index: 150;
    /*position: relative;*/
    position: absolute;
    top: 0;
    width: 100%;
}

.slide__product {

    width: 50%;
    display: flex;
    flex-direction: column;
}

.slide__product--one {
    width: 100%;
}

@media (max-width: 795px) {
    .slide {
        /*height: 92px;*/
    }

    .slide__title {
        font-size: 12px;
        padding: 15px 0 0 15px;
        margin-bottom: 10px;
    }

    .slide__points {
        font-size: 12px;
        padding: 5px 0 5px 15px;
    }
    .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets
    {
        bottom: 2px !important;
    }

    .swiper-pagination-bullet {
        width: 5px !important;
        height: 5px !important;
    }

    .slide__title-text--red {
        background-color: #C2044B;
        padding: 5px;
        border-radius: 5px;
    }
}
