.face-value-selector__body {
  display: flex;
  flex-wrap: wrap;
}

.face-value-selector__title {
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 500;
}

.face-value-selector__face-value {
  padding: 10px 15px;
  border: 1px solid #ffffff;
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  font-weight: 600;
  background-color: #ececec;
  color: #333333;
}

.face-value-selector__face-value--selected {
  background-color: #202634;
  color: white;
  border-color: #202634;
  font-weight: 600;
}
