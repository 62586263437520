.wave-products-page {
    /*padding-top: 120px;*/
}

.wave-products-banner {
    background-color: black;
    width: 100%;
    border-radius: 10px;
    margin-bottom: 25px;
    position: relative;
}

.wave-products-banner img {
    border-radius: 10px;
    max-width: 100%;
    max-height: 100%;
    display: block;
    opacity: 0.6;
}

.wave-products-banner__title {
    position: absolute;
    top: 0;
    padding: 40px;
    color: white;
    font-size: 36px;
    font-weight: bold;
}

.wave-products-banner__title--red {
    padding: 10px;
    margin: 30px;
    background-color: #C2044B;
    border-radius: 5px;
}

.wave-product-table {
    margin-bottom: 25px;
    border-collapse: collapse;
}

.wave-product-table, .wave-product-table td, .wave-product-table th {
    border: 1px solid #868686;
}

.wave-product-table-row {
}

.wave-product-table_point-cell {
    width: 120px;
    text-align: center;
}

.wave-product-table_point-cell--double {
    color: #C2004B;
}

.wave-product-table_vendorCode-cell {
    text-align: center;
}


@media (max-width: 786px) {
    .wave-products-banner__title {
        padding: 10px;
        font-size: 14px;
    }

    .wave-products-banner__title--red {
        padding: 10px;
        margin: 10px;
    }
}
