.add-sale__error{
    font-size: 16px;
    color: #C2044B;
    font-weight: 500;
    margin: 20px 0;
}

.add-sale__change-dealer-link {
    vertical-align: center;
    margin: auto;
    padding: 0 5px;
}