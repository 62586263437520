.activate-code-page {
   /*padding-top: 140px;*/
}

.activate-code-page__title {
    margin-bottom: 20px;
}

.activate-code-page__body {
    /*display: flex;*/
}

.activate-code-hint {
    margin-top: 5px;
    font-weight: 500;
    font-size: 16px;
    color: #999999;
}