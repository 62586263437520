.change-email-modal-window {
  width: 350px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.change-email-modal-window__body {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.change-email-modal-window__footer {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 25px;
}

.change-email-modal-window__input {
  width: 300px;
  margin-bottom: 20px;
}

.change-email-message {
  font-size: 12px;
  font-weight: 500;
  margin: 5px 0 0 0;
}

.change-email-message--error {
  color: #C2044B;
}

.change-email-modal-window__renew-link {
  font-size: 14px;
  font-weight: 500;
  color: #007BFF;
  cursor: pointer;
}