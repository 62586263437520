.search-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin: 0 auto;
    /*border: solid 2px #3884be;*/
}

.search-input {
    background-color: #ececec;
    width: 100%;
    height: 32px;
    padding: 10px 35px 10px 10px;
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    /*border-radius: 4px 0 0 4px;*/
    /*border-color: #3884be !important;*/
    border: none;
    border-radius: 3px;
    outline: none;
}


.search-wrapper > button {
    /*margin-left: -4px;*/
    /*border-radius: 0 4px 4px 0;*/
    /*height: 40px;*/
}

.search-icon {
    position: absolute;
    /*right: 10px;*/
    /*top: 50%;*/
    /*transform: translateY(-50%);*/
    color: red;
    /*font-size: 20px;*/
    cursor: pointer;
}