.tooltip {
    position: relative;
    display: inline-flex;
}

.tooltip__toggler {
    display: flex;
}

.tooltip__toggler:hover {
    cursor: pointer;
}

.tooltip__content {
    position: relative;
    width: 300px;
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(10px);
    padding: 15px;
    white-space: pre-wrap;
    text-decoration: none;
    z-index: 12000;
    color: black;
    border-radius: 8px;
    text-align: left;
    line-height: 1.7em;
}

.tooltip__content-align-left {
    text-align: left;
}

.tooltip__content-align-center {
    text-align: center;
}

.tooltip__content-align-right {
    text-align: right;
}
.tooltip__content::after {
    width: 0;
    height: 0;
    border: 6px solid transparent;
    content: '';
    position: absolute;
    z-index: 13000;
}

.tooltip__content-top {
    bottom: 100%;
    transform: translate(-50%);
    margin-bottom: 10px;
    position: absolute;
    box-shadow: 0 0.5rem 1.5rem 0 rgba(0, 0, 0, 0.1);
}

.tooltip__content-left {
    top: 50%;
    right: 100%;
    margin-right: 10px;
    position: absolute;
    box-shadow: 0.5rem 0.5rem 1.5rem 0 rgba(0, 0, 0, 0.1);
}

.tooltip__content-bottom {
    top: 100%;
    transform: translate(-50%);
    margin-top: 10px;
    position: absolute;
    box-shadow: 0 -0.5rem 1.5rem 0 rgba(0, 0, 0, 0.1);
}

.tooltip__content-right {
    top: 50%;
    left: 100%;
    margin-left: 10px;
    position: absolute;
    box-shadow: -0.5rem 0.5rem 1.5rem 0 rgba(0, 0, 0, 0.1);
}

.tooltip-enter {
    opacity: 0;
}

.tooltip-enter-active {
    transition: all 200ms;
    opacity: 1;
    max-height: 30rem;
}

.tooltip-exit {
    opacity: 1;
}

.tooltip-exit-active {
    transition: all 200ms;
    opacity: 0;
}

@media (min-width: 375px) and (max-width: 450px) {
    .tooltip__content {
        width: 250px;
    }
}

@media (max-width: 375px) {
    .tooltip__content {
        width: 150px;
    }
}