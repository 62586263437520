.cart-counter {
  display: flex;
  font-weight: 500;
}

.cart-counter__value {
  margin: 0 10px;
  padding: 0 10px 10px 10px;
  border-bottom: 1px solid #999999;
}

.cart-counter__control {
  cursor: pointer;
  position: relative;

}

.cart-counter__el {
  position: relative;
  z-index: 100;

  width: 20px;
  text-align: center;
   line-height: 20px;
}

.cart-counter__bg {
  height: 20px;
  width: 20px;
  position: absolute;
  background-color: #ececec;
  left: 0px;
  top: 0px;
  border-radius: 10px;
  display: none;
}

.cart-counter__el:hover + .cart-counter__bg {
  display: block;
}

@media (max-width: 786px) {
  .cart-counter {
    padding: 0 10px;
  }
}
