.order-status {
    font-size: 16px;
    font-weight: 600;
    /*margin-bottom: 5px;*/
}

.order-status--processing {
    color: #0074C2;
}

.order-status--completed {
    color: #53B741;
}

.order-status--rejected {
    color: #C2044B;
}

@media (max-width: 786px) {
    .order-status {
        font-size: 12px;
    }
}