.notification {
    padding: 10px 25px 10px 20px;
    border-radius: 5px;
    margin: 5px 0;
    position: relative;
    color: #333;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5em;
    text-align: justify;
}

.notification--warning {
    background-color: #ffde6f;
}

.notification__close {
    position: absolute;
    right: 5px;
    top: 5px;
}

.notification__close::after{
    display: inline-block;
    content: "\00d7";
    font-size: x-large;
    cursor: pointer;
    line-height: 16px;
}
@media (max-width: 786px) {
    .notification {
        font-size: 12px;
    }
}
