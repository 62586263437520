.arrow {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
}

.arrow--right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.arrow--left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.arrow--up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.arrow--down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
