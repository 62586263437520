.select-list {
    width: 100%;
    height: 60px;
    position: relative;
    background-color: #ececec;
    border-radius: 5px;
    border: 1px solid white;
    cursor: pointer;
}

.select-list--disabled {
    cursor: default;
}
.select-list-value {
}

.select-list-value__label {
    color: #868686;
    width: 100%;
    position: absolute;
    font-weight: 500;
    padding: 0 20px;
    z-index: 50;
    transition: all 100ms;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px;
    height: 60px;
    line-height: 60px;
}

.select-list-value__label--selected {
    font-size: 11px;
    height: 35px;
    line-height: 35px;
}

.select-list-value__arrow {
    position: absolute;
    right: 15px;
    top: 20px;
    border-color: #868686;
}

.select-list--opened .select-list-value__arrow {
    top: 25px;
}

.select-list--disabled .select-list-value__arrow  {
    border-color: #868686;
}

.select-list__items {
    background-color: white;
    position: absolute;
    top: 63px;
    z-index: 100;
    width: 100%;
    padding: 10px 0;
    box-shadow: 0 3px 6px #00000029;
    border: 1px solid #ececec;
    border-radius: 5px;
    cursor: pointer;
}
.select-list__item {
    line-height: 2;
    padding: 0px 20px;
    font-weight: 500;
    font-size: 18px;
}
.select-list__item:hover {
    background-color: #ececec;
}


.select-list-value__text {
    height: 30px;
    line-height: 30px;
    padding: 0 20px;
    font-size: 18px;
    font-weight: 500;
    position: absolute;
    cursor: text;
    bottom: 5px;
}

.select-list--disabled .select-list-value__text  {
    color: rgb(84, 84, 84);
}


@media (max-width: 320px) {
    .select-list-value__text {
        font-size: 12px;
    }

    .select-list-value__label {
        font-size: 10px;
    }

    .select-list-value__label--selected {
        font-size: 9px;
    }
}

@media (min-width: 321px) and (max-width: 768px) {
    .select-list-value__text {
        font-size: 14px;
    }

    .select-list-value__label {
        font-size: 12px;
    }

    .select-list-value__label--selected {
        font-size: 11px;
    }
}