.back-arrow-wrapper {
    background-color: #ececec;
    font-size: 10px;
    font-weight: 700;
    left: 0;
    position: fixed;
    display: flex;
    align-items: center;
    padding: 15px;
    top: 21px;
}

.back-arrow-wrapper a {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.back-arrow-wrapper .arrow, a .back-arrow-wrapper span {
    color: #333333;
    border-color: #333333;
}



@media (max-width: 1533px) {
    .back-arrow-text {
        display: none;
    }
}

@media (max-width: 1280px) {
    /*.back-arrow-wrapper {*/
    /*    display: none;*/
    /*}*/
}

