.confirmation-resend-order-to-email-modal-window {
    max-width: 400px;
    text-align: center;
}

.confirmation-resend-order-to-email-modal-window__title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 40px;
}

.confirmation-resend-order-to-email-modal-window__body {
    /*display: flex;*/
}

.confirmation-resend-order-to-email-modal-window__content {
    margin-bottom: 40px;
}

.confirmation-resend-order-to-email-modal-window__button > button {
    width: 100%;
}

@media (max-width: 786px) {
    .confirmation-resend-order-to-email-modal-window__title {
        font-size: 21px;
    }
}