.thank-for-order-page {
  /*padding-top: 180px;*/
}

.thank-for-order-page__center {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.thank-for-order-page__icon {
  width: 200px;
  height: 200px;
  background-image: url("../assets/icons/good-bag.svg");
    margin-bottom: 40px;
  background-repeat: no-repeat;
}

.thank-for-order-page__title {
  color: #333333;
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
}

.thank-for-order-page__text {
  text-align: center;
  font-size: 18px;
  color: #333333;
  margin-bottom: 40px;
}
