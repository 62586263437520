.footer {
}

.footer__row {
    padding: 30px 0;
    display: flex;
    flex-wrap: wrap;
}

.footer__link {
    margin-right: 40px;
}

.footer__link > a {
    color: #333333;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    white-space: nowrap;
}

.footer__separate {
    flex-grow: 1;
}

.footer__row:first-child {
    border-bottom: 1px solid #cdcdcd;
}

.footer__logo,
.footer__copy {
    color: #868686;
}

@media (max-width: 786px) {
    .footer__link {
        margin-right: 10px;
    }

    .footer__link > a {
        font-size: 12px;
    }

    .footer__row {
        padding: 20px 0;
    }
}