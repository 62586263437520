.cart-widget {
  background-image: url("../../assets/icons/cart.svg");
  height: 50px;
  width: 50px;
  border-radius: 100px;
  background-color: #ececec;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.cart-widget__total {
  position: absolute;
  bottom: -10px;
  width: 72px;
  left: -10px;
  text-align: center;
  cursor: auto;
}

.cart-widget__total-text {
  padding: 1px 7px;
  border-radius: 10px;
  white-space: nowrap;
  background-color: #3884be;
  color: white;
  font-weight: 500;
  font-size: 12px;
  display: inline-block;
  cursor: pointer;
}

.cart-widget__total-text--overflow {
  background-color: #c2004b;
}

.fade-enter{
  opacity: 0;
  transform:  scale(0);
}
.fade-exit{
  opacity: 1;
  transform:  scale(1);
}
.fade-enter-active{
  opacity: 1;
  transform: scale(1);
}
.fade-exit-active{
  opacity: 0;
  transform:  scale(2);
}
.fade-enter-active,
.fade-exit-active{
  transition: opacity 500ms, transform 500ms;
}