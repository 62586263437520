.button-group {
  display: inline-block;
}

.button-group__body {
  display: flex;
}

.button-group__button {
  padding: 10px 15px;
  color: #222222;
  font-weight: 600;
  margin-right: 1px;
  background-color: #ececec;
  cursor: pointer;
}

.button-group__button:hover {
  background-color: #cccccc;
}

.button-group__button--active {
  background-color: #0074c2;
  color: white;
}

.button-group__button--active:hover {
    background-color: #0074c2;
    color: white;
}

.button-group__button:first-child {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.button-group__button:last-child {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}
