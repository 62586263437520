.layout {
  /*border: 5px solid red;*/
  min-height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.layout__header {
  /*border: 5px solid black;*/
  /*height: 100px;*/
  width: 100%;
  /*position: absolute;*/
  z-index: 1000;
  display: flex;
  padding-bottom: 10px;
}

.layout__body {
  /*border: 5px solid green;*/
  width: 100%;
  flex-grow: 1;
  /*padding-top: 20px;*/
}

.layout__footer {
  background-color: #ececec;
}

.layout__container {
  margin: auto;
  max-width: 1200px;
  position: relative;
  width: 100%;
}

.page-enter {
  opacity: 0;
  top: 0;
  width: 100%;
  position: absolute;
  z-index: 100;
}

.page-enter-active {
  opacity: 1;
  transition: opacity 300ms, transform 300ms;
}

.page-exit {
  opacity: 1;
}

.page-exit-active {
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
  z-index: 50;
}

@media (max-width: 1200px) {
  .layout__container {
    padding: 0 5px;
  }
}

@media (max-width: 1150px) {
}
