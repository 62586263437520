.total__body{
    display: flex;
    font-weight: 500;
    font-size: 16px;
}

.total__text{
    font-weight: 500;
    font-size: 16px;
    margin-right: 10px;
}

.total__value{
    font-weight: bold;
    font-size: 18px;
    margin-right: 10px;

}


.total__hint{
    color: #999999;
    font-size: 12px;
}