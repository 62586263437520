.wave-card{
    position: relative;
    background:#000;
    display: flex;
    flex-direction: column;
    height: 300px;
    overflow: hidden;
    border-radius: 10px;
}

.wave-card__background{
    position: absolute;
    width: 100%;
    background-color: #222222;
    opacity:0.4;
    z-index: 50;
}

.wave-card__title{
    color: white;
    z-index: 100;
    font-size: 28px;
    padding: 40px;
    font-weight: bold;
}

.wave-card__separator{
    flex-grow: 1;
}

.wave-card__points{
    display: flex;
    z-index: 100;
    color: white;
    padding: 40px;
}

.wave-card__point-success{
    margin-right: 40px;
}

.wave-card__point-title{
    margin-bottom: 3px;
    font-size: 14px;
    font-weight: 300;
}

.wave-card__point-value{
    margin-bottom: 3px;
    font-size: 18px;
    font-weight: 500;
}