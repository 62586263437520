.form-section{

}

.form-section__title{
    font-size: 24px;
    font-weight: 300;
    margin-bottom: 10px;

}

.form-section__body{

}

@media (max-width: 700px) {
    .form-section__title {
        font-size: 18px;
    }
}