.booklets-page {
    /*padding-top: 140px;*/
}

.booklets-page__title {
    margin-bottom: 30px;
}

.booklets {
    display: flex;
    flex-wrap: wrap;
}

@media (max-width: 960px) {
    .booklets {
        display: flex;
        flex-direction: column;
    }
}
