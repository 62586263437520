.button {
    border: none;
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
    color: #146ca7;
    cursor: pointer;
    outline: none;
    transition: background-color 500ms;
}

.button:hover {
    border: 1px solid #0074c2;
    color: #0074c2;
}

.button--block {
    display: block;
    width: 100%;
    font-size: 16px;
}

.button--big {
    padding: 15px 20px;
    font-size: 16px;
}

.button--middle {
    padding: 10px 15px;
    font-size: 14px;
}


.button--primary {
    background-color: #3884be;
    color: white;
}

.button--red {
    background-color: #C2044B;
    color: white;
}


.button--primary:hover {
    background-color: #1E9DF4;
    color: white;
    border: none;
}

.button--red:hover {
    background-color: #ee2e71;
    color: white;
    border: none;
}

.button--primary:active {
    background-color: #146CA7;
    color: white;
    border: none;
}

.button--transparent {
    background: none;
}

.button--primary.button--transparent {
    border: 1px solid #0074C2;
    color: #3884be;
    background: none;
}

.button--primary.button--transparent:hover {
    border: 1px solid #0093F5;
    color: #0093F5;
    background: none;
}

.button--white.button--transparent {
    border: 1px solid white;
    color: white;
}

.button--black.button--transparent {
    border: 1px solid #393834;
    color: #333333;
}

.button__children {
    display: inline-block;
}

.button__text {
    display: inline-block;
}

.button__text-icon {
    margin-left: 5px;
    display: inline-block;
}

.button__wrap {
    display: flex;
    align-items: center;
}

.button:disabled {
    opacity: 0.7;
}

@media (max-width: 795px) {
    .button--middle {
        padding: 5px 5px;
        font-size: 12px;
    }

    .button--big {
        padding: 9px 7px;
        font-size: 14px;
    }
}