.user-bar {
  display: flex;
  cursor: pointer;
  position: relative;
}

.user-bar__body {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;
  background-color: white;

  border-radius: 5px;
}

.user-bar__name {
  font-weight: 600;
  padding-bottom: 3px;
  position: relative;
  display: flex;
  align-items: center;
}

.user-bar__name > * {
  display: flex;
}

.user-bar__name > *:first-child {
  margin-right: 3px;
}

.user-bar__balance {
  display: flex;
  font-weight: 500;
}

.user-bar__balance-title {
  color: #878787;
}

.user-bar__balance-value {
  margin-left: 5px;
}

.user-bar__submenu {
  position: absolute;
  top: 17px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #ececec;
  z-index: 100;
  right: 0;
  box-shadow: 0 3px 6px #00000029;
}

.user-bar__submenu-link {
  padding: 10px 20px;
  color: #222222;
  font-weight: 600;
  display: block;
  text-decoration: none;
  white-space: nowrap;
}

.user-bar__submenu-link:hover {
  background-color: #ececec;
}

@media (max-width: 786px) {
  .user-bar__submenu--mob-hide {
    display: none;
  }
}

.submenu-enter {
  opacity: 0;
}

.submenu-enter-active {
  opacity: 1;
  transition: opacity 200ms, top 200ms;
}

.submenu-exit {
  opacity: 1;
}

.submenu-exit-active {
  opacity: 0;
  transition: opacity 200ms, top 200ms;
}
