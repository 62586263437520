.create-wave-page {
  /*padding-top: 140px;*/
}

.create-wave-page__title {
  padding: 0 0 20px 0;
}

.create-wave-page__body {
  display: flex;
}

.create-wave-page__form {
  width: 100%;
}

.create-wave-page__description {
  display: flex;
  flex-direction: column;
  padding-left: 180px;
}

.create-wave-page__description-body {
  margin-bottom: 50px;
}

.create-wave-page__description-title {
  margin-bottom: 10px;
}

.create-wave-page__description-text {
  font-size: 14px;
  font-weight: 500;
}

@media (max-width: 786px) {
  .create-wave-page__body {
    display: flex;
    flex-wrap: wrap;
  }

  .create-wave-page__description {
    padding-left: 0;
  }
}