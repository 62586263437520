@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  /*border: 5px solid black;*/
  display: flex;
  width: 100%;
  min-height: 100%;
}

body {
  /*border: 5px solid green;*/
  margin: 0;
  width: 100%;
  min-height: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
}

#root {
  /*border: 5px solid blue;*/
  min-height: 100%;
  display: flex;
}

@media (max-width: 795px) {
  body {
    /*border: 5px solid green;*/
    font-size: 12px;
  }
}
