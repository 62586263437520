.sale-status{
    display: flex;
    align-items: center;
}

.sale-status__icon{
    height: 10px;
    width: 10px;

    margin-right: 5px;
    border-radius: 10px;
}

.sale-status__icon--success{
background-color: #53B741;
}

.sale-status__icon--wait{
    background-color: #868686;
}

.sale-status__icon--rejected{
    background-color: #ffde6f;
}