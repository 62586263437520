.data-list {
  position: relative;
  width: 100%;
}

.data-list__text-input {
}

.data-list__data {
  position: absolute;
  width: 100%;
  z-index: 400;
  border: 1px solid #dedede;
  box-shadow: 0 8px 14px #00000029;
  background-color: white;
  top: 55px;
  border-radius: 5px;
}

.data-list__check {
  margin-right: 10px;
}

.data-list__item {
  padding: 10px;
  font-size: 16px;
  color: #2e3546;
  font-weight: 500;
  cursor: pointer;
  display: flex;
}

.data-list__button {
  padding: 20px 0;
  text-align: center;
}

.data-list__item:hover {
  background-color: #efefef;
}

.data-list__data-container {
  max-height: 300px;
  overflow: hidden;
  overflow-y: auto;
}

.data-list__data-enter {
  opacity: 0;
}

.data-list__data-enter-active {
  opacity: 1;
  transition: opacity 200ms, top 200ms;
}

.data-list__data-exit {
  opacity: 1;
}

.data-list__data-exit-active {
  opacity: 0;
  transition: opacity 200ms, top 200ms;
}
