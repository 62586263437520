.nav-bar-mobile__items {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 36px;
}

.nav-bar-mobile__item {
    width: max-content;
}

.nav-bar-mobile__modal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 375px;
    height: 813px;
}

.nav-bar-mobile__link {
    text-align: center;
    margin-bottom: 30px;
}

.nav-bar-mobile__closeBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    position: absolute;
    top: 9px;
    right: 21px;
    font-size: 20px;
}
.nav-bar-mobile__closeBtn:after {
    display: inline-block;
    content: "\00d7"; /* This will render the 'X' */
    font-size: xxx-large;
    cursor: pointer;
}

@media (max-width: 375px) and (max-height: 813px) {
    .nav-bar-mobile__modal {
        width: 100%;
        height: 100%;
    }
}

@media (min-width: 375px) and (max-height: 813px) {
    .nav-bar-mobile__modal {
        width: 375px;
        height: 100%;
    }
}