.support-page {
  /*padding-top: 140px;*/
}

.support-page__body {
  display: flex;
}

.support-page__form {
  width: 50%;
  padding-right: 140px;
}

.support-page__contacts {
  width: 50%;
}

.support-page__title {
  margin-bottom: 60px;
}

.support-page__form-title, .support-page__contacts-title {
  margin-bottom: 40px;
}

.support-page__contact-title {
  color: #868686;
  font-size: 12px;
  margin-bottom: 8px;
}

.support-page__faq {
    margin-bottom: 40px;

}

.support-page__contact-value {
  color: #3884BE;
  font-size: 24px;
  margin-bottom: 5px;
  font-weight: bold;
}

.support-page__contact-item {
  margin-bottom: 30px;
}

@media (max-width: 786px) {
  .support-page__body {
    display: flex;
    flex-direction: column-reverse;
  }
  .support-page__form {
    width: 100%;
    padding-right: 0;
  }
  .support-page__form .button {
    width: 100%;
  }
  .support-page__contacts {
    width: 100%;
  }
  .support-page__title {
    margin-bottom: 15px;
  }
 .support-page__form-title, .support-page__contacts-title {
    margin-bottom: 10px;
  }
  .support-page__contact-value {
    font-size: 18px;
  }

  .support-page__contact-item {
    margin-bottom: 15px;
  }
}