.order-event {
    display: flex;
    align-items: start;
    cursor: pointer;
}

.order-event__image {
    margin-right: 50px;
    position: relative;
}

.order-event__image:after {
    content: "";
    position: absolute;
    top: -17px;
    left: -22px;
    z-index: 50;
    right: -20px;
    bottom: -16px;
    background-position: center;
    background-size: contain;
    background-image: url("../../assets/images/csrds-group.svg");
    background-repeat: no-repeat;

}

.order-event__image img {
    border-radius: 5px;
    display: block;
    width: 187px;
    z-index: 150;
    position: relative;
}

.order-event__body {
    width: 700px;
    padding-right: 100px;
}

.order-event__description {

}

.order-event__description > div {
    margin-bottom: 5px;
}

.order-event__title {
    font-size: 24px;
    color: #333333;
    font-weight: bold;
    margin-bottom: 20px;
}

.order-event__products {
    display: flex;
    flex-wrap: wrap;

}

.order-event__product {
    display: flex;
    margin-right: 15px;
    margin-bottom: 15px;
    padding: 10px;
    background-color: #ECECEC;
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px;
}

.order-event__total {
    font-size: 36px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 5px;
}

.order-event__status {
    font-size: 16px;
    font-weight: 600;
    color: #53B741;
    margin-bottom: 5px;
}

.order-event__date {
    font-size: 16px;
    font-weight: 600;
    color: #868686;
    margin-bottom: 5px;
    margin-top: 5px;
}

.order-event__product-name {
    white-space: nowrap;
}

.order-event__product-quantity {
    color: #868686;
    margin-left: 10px;
}

@media (max-width: 786px) {
    .order-event__description {
        width: 240px;
        text-align: right;
    }

    .events-page__item {
        margin-bottom: 10px;
    }

    .order-event__date {
        font-size: 9px;
    }

    .order-event__status {
        font-size: 12px;
    }

    .order-event__total {
        font-size: 18px;
    }

    .order-event__body {
        padding-right: 10px;
        width: 500px;
    }

    .order-event__product {
        margin-right: 5px;
        margin-bottom: 5px;
        padding: 5px;
        font-size: 11px;
        font-weight: 500;
        border-radius: 3px;
    }

    .order-event__title {
        font-size: 16px;
        margin-bottom: 10px;
    }

    .order-event__image {
        margin-right: 20px;
    }

    .order-event__image img {
        border-radius: 3px;
        width: 65px;
    }

    .order-event__image:after {
        top: -6px;
        left: -28px;
        z-index: 50;
        right: -25px;
        bottom: -5px;
    }
}