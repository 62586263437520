.form{
    display: flex;
    flex-direction: column;
}

.form__title{
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: 600;
}

@media (max-width: 700px) {
    .form__title {
       font-size: 18px;
    }
}