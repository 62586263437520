.about-products-page {
    /*padding-top: 140px;*/
}

.about-products-page__title {
    margin-bottom: 30px;
}

.about-products-page__title--main {
    margin-bottom: 40px;
}

.about-products-page__text {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 60px;
    max-width: 850px;
}

.about-products-page__section {
    background-color: #F2F4F6;
    padding: 40px 50px;
    margin-bottom: 80px;
}

.about-products-page__section-body {
    display: flex;
    align-items: center;
}

.about-products-page__section-title {
    display: flex; 
    align-items: center;
    margin-bottom: 30px;
}

.about-products-page__section-title img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
}

.about-products-page__section-content {
    padding-left: 50px;
    width: 50%;
}

.about-products-page__section-video {
    width: 50%;
}

.about-products-page__section-video iframe {
    display: block;
    width: 100%;
}

.about-products-page__section-video-link {
    text-align: center;
    padding-top: 5px;
}

.about-products-page__section-video-link a {
    color: #0f5b99;
    text-decoration: none;
    cursor: pointer;
}

.about-products-page__section-video-link a:hover {
    text-decoration: underline;
}

.html5-video-player a {
    color: inherit;
    text-decoration: none;
    -moz-transition: color .1s cubic-bezier(0.0, 0.0, 0.2, 1);
    -webkit-transition: color .1s cubic-bezier(0.0, 0.0, 0.2, 1);
    transition: color .1s cubic-bezier(0.0, 0.0, 0.2, 1);
    outline: 1px solid red;
}

@media (max-width: 786px) {
    .about-products-page__section {
        padding: 30px;
    }

    .about-products-page__section-body {
        display: flex;
        flex-direction: column;
    }

    .about-products-page__section-content {
        padding-left: 0;
        width: 100%;
    }
    
    .about-products-page__section-video {
        width: 100%;
        position: relative;
        padding-bottom: 57.25%;
        padding-top: 25px;
        height: 0;
        margin-bottom: 30px;
    }

    .about-products-page__section-video iframe {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }

    .about-products-page__section-video-link {
        padding-top: 57.25%;
    }
}

@media (max-width: 295px) {
    .about-products-page__section-video {
        margin-bottom: 35px;
    }
}