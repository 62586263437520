.paragraph{
    margin-top: 0;
    margin-bottom: 30px;
    font-size: 16px;
    font-weight: 500;
}

.paragraph--think{
    font-weight: 300;
}

.paragraph:last-child{
    margin-bottom: 0;
}